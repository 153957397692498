import { RGBA } from "eplayer-core";

export const CURRENT_MODEL_VERSION = "1.6.5";

export const TOOLTIP_MIN_HEIGHT = 15;
export const FPS = 24;
export const MENU_HIDE_DELAY = 150;
export const SEQUENCE_OFFSET_X = 200;
export const CHAPTER_OFFSET_X = 1000;
export const STRUCTURE_MOUSE_MODES = {
  INACTIVE: 0,
  ACTIVE: 1,
  DRAGGING: 2,
  PANNING: 3,
};
export const LAYOUT_MOUSE_MODES = {
  INACTIVE: 0,
  ACTIVE: 1,
  SELECTING: 2,
  DRAG_SELECTING: 3,
  TRANSLATING: 4,
  SCALING: 5,
  ROTATING: 6,
  PANNING: 7,
};

export const TIMELINE_PANEL_DEFAULT_HEIGHT = 0;
export const TIMELINE_HEADER_HEIGHT = 95;
export const TIMELINE_ASSERT_MIN_HEIGHT = 20;
export const TIMELINE_AUDIO_MIN_HEIGHT = 0;

export const CONTROL_SIZE = 12;
export const SELECTION_CONTROL_SIZE = 1;
export const SELECTION_COLOR = RGBA(80, 233, 186, 1);
export const CORNER_ANCHOR_COLOR = RGBA(80, 233, 186, 1);
export const ROTATE_ANCHOR_COLOR = RGBA(80, 233, 186, 1);
export const EDGE_ANCHOR_COLOR = RGBA(80, 233, 186, 0);
// Template variables are objects to hold style (normal, numbered) and build (add, new) data
export const TEMPLATE_VAR_DEFAULT = {
  value: "",
  style: "normal",
  build: "new",
};
export const VIEWPORT_MINHEIGHT = 120;
export const TIMELINE_MINHEIGHT = 55;
export const TIMELINE_DEFAULT_HEIGHT = 300;
export const TIMELINE_SNAP_HEIGHT = 100;
export const TIMELINE_LABEL_WIDTH = 250;
export const TIMELINE_PAD_RIGHT = 15;
export const TIMELINE_DEFAULT_INTERVAL = 5;
export const TIMELINE_INTERVAL_THRESHOLD = 120;
export const TIMELINE_PLAYHEAD_WIDTH = 22;
export const TIMELINE_EASING_OPTIONS = [
  { value: "linear", label: "Linear (default)" },
  { value: "sineInOut", label: "Ease-In-Out" },
  { value: "sineIn", label: "Ease-In" },
  { value: "sineOut", label: "Ease-Out" },
  { value: "step", label: "Step" },
];

export const MOUSE_BUTTON = {
  LEFT: 0,
  MIDDLE: 1,
  RIGHT: 2,
};
export const WIDGETS_PANEL_HEIGHT = 80;
export const TOOLBAR_ADJUST = 48;
// White-list for validating if the dropped data is coming from a compatible panel
export const ONDROP_WHITELIST = {
  Script: ["Script", "Media"],
  StructureViewport: ["StructureWidgets"],
  StructureHierarchy: ["StructureWidgets", "StructureHierarchy"],
  LayoutViewport: ["LayoutWidgets", "Media"],
  LayoutHierarchy: ["LayoutWidgets", "LayoutHierarchy", "Media"],
};
export const VARIABLE_VALUE_OPTIONS = {
  YES_OR_NO: [
    {
      value: "0",
      label: "No",
    },
    {
      value: "1",
      label: "Yes",
    },
  ],
  QUESTION_MODE: [
    {
      value: "0",
      label: "Off",
    },
    {
      value: "1",
      label: "Notepad",
    },
    {
      value: "2",
      label: "Questions",
    },
  ],
  CAPTIONING: [
    {
      value: "0",
      label: "Off",
    },
    {
      value: "1",
      label: "On",
    },
    {
      value: "2",
      label: "Always On",
    },
  ],
  VIEW_GROUPS: [
    {
      value: "1",
      label: "Patient",
    },
    {
      value: "2",
      label: "Guardian",
    },
    {
      value: "3",
      label: "Patient and Guardian",
    },
    {
      value: "4",
      label: "Friend or Family",
    },
  ],
  GENDER: [
    {
      value: "MALE",
      label: "Male",
    },
    {
      value: "FEMALE",
      label: "Female",
    },
    {
      value: "UNKNOWN",
      label: "Unknown",
    },
  ]
}
export const VARIABLE_VALUE_MAP = {
  alwaysAllowSkipping: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "0",
    showValue: true,
  },
  neverContinueWhereLeftOff: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "0",
    showValue: true,
  },
  useDisclaimer: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "1",
    showValue: true,
  },
  questionMode: {
    values: VARIABLE_VALUE_OPTIONS.QUESTION_MODE,
    default: "2",
    showValue: true,
  },
  anonymous: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "0",
    showValue: true,
  },
  nonBranded: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "0",
    showValue: true,
  },
  gender: {
    values: VARIABLE_VALUE_OPTIONS.GENDER,
    default: null,
    showValue: false,
  },
  viewGroup: {
    values: VARIABLE_VALUE_OPTIONS.VIEW_GROUPS,
    default: "1",
    showValue: true,
  },
  isCapturing: {
    values: VARIABLE_VALUE_OPTIONS.YES_OR_NO,
    default: "0",
    showValue: true,
  },
}

export const MANAGER_ROUTES = Object.freeze({
  ADVISORLINKS: "AdvisorLink",
  DETAILS: "Details",
  HOMEPAGE: "Homepage",
  LOADER: "Loader",
  QUEUE: "Queue",
});

export const TASK_SORT_ORDER = Object.freeze({
  Author: "creator",
  Name: "name",
  Position: "position",
  Status: "status",
});

export const CONFIG_KEYS = Object.freeze({
  CAPTURE_TASK_POLL_MS: "CAPTURE_TASK_POLL_MS",
});

export const TEMPLATE_PROPS = {
  name: true,
  objectId: true,
  updatedAt: true,
  updatedBy: true,
  template: true,
  properties: true,
};

export const TEMPLATE_PROPS_EXCLUDE = {
  active: true,
  opacity: true,
  position: true,
  rotation: true,
  scale: true,
  canvasFrame: true,
  template: true,
  htmlFor: true,
  value: true,
  disabled: true,
  // Checkbox
  checked: true,
  // Slider
  min: true,
  max: true,
  step: true,
  sliderTheme: true,
  // Input
  placeholder: true,
  // Button
  title: true,
};
