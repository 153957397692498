import { transcriptHtml, transcriptDocx, captionsFromEdition } from "polyp"
import base64ToBlob from "b64-to-blob"

export { readDataURL }
export { readBinaryString }
export { stripDataURLHeader }
export { xlsToTranscriptHtml }
export { xlsToTranscriptWord }
export { editionToTranscriptHtml }
export { editionToTranscriptWord }
export { editionToWebVTT }

function readDataURL(file) {
  return new Promise(function(done, fail) {
    const reader = new FileReader()

    reader.onload = function(e) {
      if (reader.result != null) done(reader.result)
      else if (reader.result === null) fail(new Error(`Could not read ${file.name} from disk`))
      else fail(reader.error)
    }

    reader.onerror = function(e) {
      fail(reader.error)
    }

    reader.readAsDataURL(file)
  })
}

function readBinaryString(file) {
  return new Promise(function(done, fail) {
    const reader = new FileReader()

    reader.onload = function(e) {
      if (reader.result != null) done(reader.result)
      else if (reader.result === null) fail(new Error(`Could not read ${file.name} from disk`))
      else fail(reader.error)
    }

    reader.onerror = function(e) {
      fail(reader.error)
    }

    reader.readAsBinaryString(file)
  })
}

function stripDataURLHeader(dataURL) {
  return dataURL.replace(/^.*,/, '')
}

async function saveFile(blob, fileName) {
  const url = URL.createObjectURL(blob)
  const anchor = document.createElement("a")
  anchor.href = url
  anchor.download = fileName
  anchor.style.display = "none"
  document.body.appendChild(anchor)
  anchor.click()
  document.body.removeChild(anchor)
  URL.revokeObjectURL(url)
}

async function xlsToTranscriptHtml(xlsFile, title, language) {
  const dataUrl = await readDataURL(xlsFile)
  const base64 = stripDataURLHeader(dataUrl)
  const html = await transcriptHtml.fromXLSBase64(title, base64, language)
  const blob = new Blob([html], { type: "text/html" })
  const fileTitle = `${title.toLowerCase().replace(" ", "_")}.html`

  return await saveFile(blob, fileTitle)
}

async function xlsToTranscriptWord(xlsFile, title) {
  const dataUrl = await readDataURL(xlsFile)
  const base64 = stripDataURLHeader(dataUrl)
  const docBase64 = await transcriptDocx.fromXLSBase64(title, base64)
  const blob = base64ToBlob(docBase64)
  const fileTitle = `${title.toLowerCase().replace(" ", "_")}.docx`

  return await saveFile(blob, fileTitle)
}

async function editionToTranscriptHtml(edition, language) {
  const html = transcriptHtml.fromEditionObject(edition, language)
  const blob = new Blob([html], { type: "text/html" })
  const title = edition.name
  const fileTitle = `${title.toLowerCase().replace(" ", "_")}.html`

  return await saveFile(blob, fileTitle)
}

async function editionToTranscriptWord(edition,) {
  const docBase64 = await transcriptDocx.fromEditionObject(edition)
  const blob = base64ToBlob(docBase64)
  const title = edition.name
  const fileTitle = `${title.toLowerCase().replace(" ", "_")}.docx`

  return await saveFile(blob, fileTitle)
}

async function editionToWebVTT(edition, language) {
  const name = edition.name
  const blob = new Blob([captionsFromEdition(edition, language)], { type: "text/vtt" })

  return await saveFile(blob, name)
}
