import React from "react";
import { observer } from "mobx-react";
import { connectionsWhere } from "../models/queries";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import { getTimeStamp, secondsToDurationString } from "../utils";
import Select from "react-select";
import { MAX_SEQUENCE_DURATION } from "../models/constants";
import { setNext } from "../actions/edition";
import { setSequenceDuration } from "../actions/edition";
import { toggleProp } from "../actions/edition";
import { updateProp } from "../actions/edition";
import { addConnection } from "../actions/edition";
import { removeConnection } from "../actions/edition";
import { updateOptionValue } from "../actions/edition";
import { updateOptionCondition } from "../actions/edition";

function getDisplayDate(d) {
  const timestamp = getTimeStamp(d);
  const date = timestamp[0];
  const time = timestamp[1];
  return `${date} ${time}`;
}

const StructureDetails = observer(({ edition, versionDetails, state }) => {
  const structure = state.structure;
  const selections = structure.selections.nodes;
  const nodeAttributes = structure.nodeAttributes;

  if (selections.length >= 1) {
    let details = selections.map((selection) => {
      if (selection.Type === "Sequence")
        return (
          <SequenceDetails
            key={selection.objectId}
            sequence={selection}
            edition={edition}
            nodeAttributes={nodeAttributes}
          />
        );
      else if (selection.Type === "DecisionNode")
        return (
          <DecisionNodeDetails
            key={selection.objectId}
            decisionNode={selection}
            edition={edition}
          />
        );
      else if (selection.Type === "Chapter")
        return <ChapterDetails key={selection.objectId} chapter={selection} />;
      else if (selection.Type === "Edition")
        return (
          <EditionDetails
            edition={selection}
            versionDetails={versionDetails}
            state={state}
          />
        );
      else return null;
    });
    return <div>{details}</div>;
  } else {
    return (
      <EditionDetails
        edition={edition}
        versionDetails={versionDetails}
        state={state}
      />
    );
  }
});

const EditionDetails = observer(({ edition, state, versionDetails }) => {
  const saveState = state.global.save;
  const updateName = (val) => updateProp("name", val, edition);
  const nodes = connectionsWhere((n) => n.Type !== "Chapter", edition);
  const tableOfContentsItem = (chapter) => {
    const toggle = (e) => updateProp("inToC", !chapter.inToC, chapter);
    return (
      <li key={chapter.objectId}>
        <label>
          <input
            type="checkbox"
            checked={chapter.inToC}
            value={chapter.inToC}
            onChange={toggle}
          />
          {chapter.name || "Chapter"}
        </label>
      </li>
    );
  };
  const tableOfContentsList = edition.chapters.map((chapter) => {
    return tableOfContentsItem(chapter);
  });

  return (
    <div className="details edition">
      <h3 className="card-title form-row">
        <span className="sprite details edition" />
        <Input
          type="text"
          placeholder="Edition Name"
          value={edition.name}
          title={edition.name}
          onSave={updateName}
        />
      </h3>
      <h4 className="card-subsection-title">Connection</h4>
      <div className="card-subsection">
        <NextConnection node={edition.startNode} nodes={nodes} />
      </div>
      <h4 className="card-subsection-title">Edition Info</h4>
      <div className="card-subsection">
        {edition.version.isUnversioned ? (
          <React.Fragment>
            <div className="form-row">
              <div className="fit key">Current Version</div>
              <div className="fill value">
                {state.global.save.timestamp === null
                  ? "(unsaved)"
                  : "(unversioned)"}
              </div>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="form-row">
              <div className="fit key">Current Version</div>
              <div className="fill value">{edition.version.displayString}</div>
            </div>
            <div className="form-row">
              <div className="fit key">Version Author</div>
              <div className="fill value">{versionDetails.lastUpdatedBy}</div>
            </div>
            <div className="form-row">
              <div className="fit key">Version Created</div>
              <div className="fill value">
                {getDisplayDate(versionDetails.latestVersionCreated)}
              </div>
            </div>
          </React.Fragment>
        )}
        <div className="form-row">
          <div className="fit key">Last Saved</div>
          <div className="fill value">
            {saveState.timestamp && saveState.lastChecked
              ? getDisplayDate(saveState.timestamp)
              : "Never"}
          </div>
        </div>
        <div className="form-row">
          <div className="fit key">Created</div>
          <div className="fill value">
            {getDisplayDate(versionDetails.createdDate)}
          </div>
        </div>
        <div className="form-row">
          <div className="fit key">Duration</div>
          <div className="fill value">
            {secondsToDurationString(edition.duration)}
          </div>
        </div>
        <div className="form-row">
          <div className="fit key">Without Risks</div>
          <div className="fill value">
            {secondsToDurationString(edition.durationWithoutRisksOrIntro)}
          </div>
        </div>
      </div>
      <h4 className="card-subsection-title">Table of Contents</h4>
      <div className="card-subsection">
        <ul className="table-of-contents">{tableOfContentsList}</ul>
      </div>
    </div>
  );
});

const ChapterDetails = observer(({ chapter }) => {
  const updateName = (val) => updateProp("name", val, chapter);
  return (
    <div className="details chapter">
      <h3 className="card-title form-row">
        <span className="sprite details chapter" />
        <Input
          placeholder="Chapter Name"
          type="text"
          value={chapter.name}
          title={chapter.name}
          onSave={updateName}
        />
      </h3>
      <div className="card-subsection">
        <div className="form-row">
          <div className="fit key">Duration</div>
          <div className="fill value">
            {secondsToDurationString(chapter.duration)}
          </div>
        </div>
        <div className="form-row">
          <div className="fit key">Without Risks</div>
          <div className="fill value">
            {secondsToDurationString(chapter.durationWithoutRisks)}
          </div>
        </div>
      </div>
    </div>
  );
});

const SequenceDetails = observer(({ sequence, edition, nodeAttributes }) => {
  const updateName = (val) => updateProp("name", val, sequence);
  const updateDuration = (val) => {
    let newDuration = parseInt(val);
    setSequenceDuration(sequence, newDuration);
  };
  const nodes = connectionsWhere(
    (n) => n.Type !== "Chapter" && n !== sequence,
    edition,
  );
  const onChangeIsProgramCompletion = () => {
    toggleProp("isProgramCompletion", sequence);
  };
  const onChangeIsMenu = () => {
    toggleProp("isMenu", sequence);
    if (sequence.isMenu) {
      updateProp("shouldCapture", false, sequence);
    }
  };
  const onChangeShouldCapture = () => {
    toggleProp("shouldCapture", sequence);
  };

  return (
    <div className="details sequence">
      <h3 className="card-title form-row">
        <span className="sprite details sequence" />
        <Input
          placeholder="Sequence Name"
          type="text"
          value={sequence.name}
          title={sequence.name}
          onSave={updateName}
        />
      </h3>
      <div className="card-subsection">
        <div className="form-row">
          <div className="fit key">Duration</div>
          <div className="fill value">
            <Input
              type="number"
              min={1}
              placeholder="1"
              name="durationControl"
              comparative={MAX_SEQUENCE_DURATION}
              value={sequence.duration}
              onSave={updateDuration}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="fit key">End of Program?</div>
          <input
            type="checkbox"
            checked={sequence.isProgramCompletion}
            onChange={onChangeIsProgramCompletion}
          />
        </div>
        <div className="form-row">
          <div className="fit key">Menu?</div>
          <input
            type="checkbox"
            checked={sequence.isMenu}
            onChange={onChangeIsMenu}
          />
        </div>
        <div className="form-row">
          <div className="fit key">Include in Video Capture?</div>
          <input
            type="checkbox"
            checked={sequence.shouldCapture}
            onChange={onChangeShouldCapture}
          />
        </div>
      </div>
      <h4 className="card-subsection-title">Connection</h4>
      <div className="card-subsection">
        <NextConnection node={sequence} nodes={nodes} />
      </div>
    </div>
  );
});

const DecisionNodeDetails = observer(({ decisionNode, edition }) => {
  const { connections } = decisionNode;
  const updateName = (val) => updateProp("name", val, decisionNode);
  const nodes = connectionsWhere(
    (n) => n.Type !== "Chapter" && n !== decisionNode,
    edition,
  );
  const optionConnections = connections.map((c, i) => {
    const confirmMsg = "Are you sure you want to remove this connection?";
    const remove = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (window.confirm(confirmMsg)) removeConnection(decisionNode, c);
    };
    return (
      <OptionConnection
        key={c.objectId}
        connection={c}
        nodes={nodes}
        remove={remove}
        index={i + 1}
      />
    );
  });
  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    addConnection(decisionNode);
  };
  return (
    <div className="details decision-node">
      <h3 className="card-title form-row">
        <span className="sprite details decisionnode" />
        <Input
          placeholder="Decision Node Name"
          type="text"
          value={decisionNode.name}
          title={decisionNode.name}
          onSave={updateName}
        />
      </h3>
      <h4 className="card-subsection-title">Connections</h4>
      <div className="card-subsection">
        <button
          type="button"
          className="add-connection no-pad spacer"
          onClick={onClick}
        >
          <i className="icon add-circle" /> Connection
        </button>
        {optionConnections}
        <NextConnection
          node={decisionNode}
          nodes={nodes}
          index={connections.length + 1}
        />
      </div>
    </div>
  );
});

const NextConnection = observer(({ node, nodes, index }) => {
  const className = node.next == null ? "goto alert" : "goto";
  const value = node.next ? node.next.objectId : "";
  const updateValue = (e) => {
    if (e) {
      setNext(
        node,
        nodes.find((n) => n.objectId === e.value),
      );
    } else {
      setNext(node, null);
    }
  };
  const options = ConnectionOptionsObject(nodes);
  return (
    <div className="connection flex-row">
      <div className="index fit">{index || "1"}</div>
      <div className="statement else fill">
        <div
          className="form-row flex-start"
          style={{ display: index ? "flex" : "none" }}
        >
          <div className="key">ELSE</div>
          <div className="value" />
        </div>
        <div className="form-row center">
          <div className="key">GoTo</div>
          <div className="value">
            <Select
              className={className}
              value={value}
              onChange={updateValue}
              options={options}
              clearable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const OptionConnection = observer(({ connection, nodes, remove, index }) => {
  // Condition stuff
  const conditionClassName = connection.condition.compiled
    ? "condition "
    : "condition alert";
  const conditionValue = connection.condition.string;
  const updateCondition = (val) => {
    updateOptionCondition(connection, val); // Expression object
  };
  // Value stuff
  const valueClassName = connection.value == null ? "goto alert" : "goto";
  const selectValue = connection.value ? connection.value.objectId : "";
  const updateValue = (e) => {
    if (e) {
      updateOptionValue(
        connection,
        nodes.find((n) => n.objectId === e.value),
      );
    } else {
      updateOptionValue(connection, null);
    }
  };
  const options = ConnectionOptionsObject(nodes);
  return (
    <div className="connection flex-row">
      <div className="index fit">{index}</div>
      <div className="statement fill">
        <button className="remove-connection" type="button" onClick={remove}>
          <i className="icon remove" />
        </button>
        <div className="form-row flex-start">
          <div className="key">IF</div>
          <div className="value">
            <TextArea
              className={conditionClassName}
              placeholder="Condition"
              type="text"
              value={conditionValue}
              onSave={updateCondition}
            />
          </div>
        </div>
        <div className="form-row center">
          <div className="key">GoTo</div>
          <div className="value">
            <Select
              className={valueClassName}
              value={selectValue}
              onChange={updateValue}
              options={options}
              clearable={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

const ConnectionOptionsObject = (nodes) => {
  var options = [{ value: null, label: "None" }];

  for (var i = 0, l = nodes.length; i < l; i++) {
    var n = nodes[i];
    options.push({ value: n.objectId, label: n.name || "Untitled " + n.Type });
  }
  return options;
};

export default StructureDetails;
